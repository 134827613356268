<template>
  <div :id="id" style="height: 100%;width: 100%;"></div>
</template>

<script>
  import { mapState } from 'vuex'
  export default{
    name:"barCharts",
    props: {
      id: {
        type: String,
        default: ''
      },
      lists:{
        type:Array,
        default:()=>[]
      },
      types:{
        type:String,
        default:'standard'//水平
      },
      outColor:{
        type:Array|String
      }
    },
    computed: {
      ...mapState({
        fontSize: state => state.main.fontSize,
      })
    },
    data() {
      return {
        myChart:null
      }
    },
    mounted () {
      this.init()
      const resize = ()=>{
        setTimeout(()=>{
          if(this.myChart){this.myChart.resize()}
        })
      }
      window.addEventListener('resize',resize)
      this.$once('hook:beforeDestroy',()=>{
        window.removeEventListener('resize',resize)
      })
    },
    watch:{
      lists(val){
        this.init()
      }
    },
    methods: {
      getxAxis(){
        return this.types==='standard'?
        {
          splitLine: {show:false},
          axisTick: {show:false},
          axisLabel:{show:false}
        }:
        {
          type: 'category',
          data: this.lists.map(v=>v.key),
          axisLabel: {
            rotate: 35,
            fontSize:this.fontSize*0.75,
            color:'#8bb9f9'
          },
          axisTick: {
              show: false,
          },
          axisLine: {
              show: false,
          },
        }
      },
      getyAxis(){
        return this.types==='standard'?
        {
          type: 'category',
          axisLabel:{
            fontSize:this.fontSize===14?this.fontSize*0.75:this.fontSize,
            color:'#8bb9f9',
            fontWeight:'bolder'
          },
          axisLine: {show:false},
          axisTick: {show:false},
          data:this.lists.map(v=>v.key)
        }:{
          type: 'value',
          splitNumber:3,
          axisLabel:{
            fontSize:this.fontSize===14?this.fontSize*0.75:this.fontSize,
            color:'#2a4f78'
          },
          axisLine: {
              show: false,
          },
          axisTick: {
              show: false,
          },
          splitLine: {
            lineStyle: {
                type: "dashed",
                color: '#1F3154'
            }
          },
        }
      },
      getgrid(){
        return this.types==='standard'?
        {
          top:'5%',right:'10%',left:'28%',bottom:'5'
        }:{
          top: '15%',right: '10',left:'18%',bottom:'40'
        }
      },
      getColor(){
        if(this.outColor){
          return this.outColor
        }
        return this.types==='standard'?
        [
          new this.echarts.graphic.LinearGradient(0, 0, 1, 0, [
            { offset: 0, color: '#56c596' },
            { offset: 1, color: '#7bea95' },
          ]),
        ]:[
          new this.echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#b16a2a' },
            { offset: 1, color: '#47B8BC' },
          ]),
        ]
      },
      init() {
        if(!this.lists||!this.lists.length){
          this.myChart&&this.myChart.clear()
          return
        }
        // 基于准备好的dom，初始化echarts实例
        if(!this.myChart){
          this.myChart = this.echarts.init(document.getElementById(this.id));
        }
        // 绘制图表

        let option = {
            grid: this.getgrid(),
            color: this.getColor(),
            tooltip: {
              trigger: 'axis',
              textStyle: {
                color: '#000',
              },
              axisPointer: {
                type: 'shadow',
              },
              backgroundColor: '#fff',
              extraCssText:'box-shadow: 0px 0px 10px 0px rgba(19,83,88,0.2);',
              // formatter(params) {
              //     let texts = ''
              //     for (let i = 0; i < params.length; i++) {
              //         if (i === 0) {
              //             texts += `${params[i].value}人<br>`
              //         }
              //         texts += `${params[i].name}<br>`
              //     }
              //     return texts
              // },
            },
            xAxis:this.getxAxis(),
            yAxis:this.getyAxis(),
            series: [
              {
                name: "名称",
                type: 'bar',
                barWidth: this.types==='standard'?'20%':'35%',
                ...this.types==='standard'&&{label: {
                    position: 'right',
                    color:'#566e90',
                    fontSize:this.fontSize===14?this.fontSize*0.75:this.fontSize,
                    formatter(item) {
                        return `${item.value}`
                    },
                    show: true,
                }},
                data: this.lists.map(v=>v.value),
              },
            ]
          }

        this.myChart.setOption(option);
      }
    },
  }
</script>