<template>
  <common-container title="实时报警" class="right-top">
    <vueInfiniteAutoScroll ref="scroller" :data="lists" :option="{newFirst:true}" class="scroller-wrap">
      <template slot-scope="props">
        <div class="flex-box">
          <span class="name-class" v-text="props.item.name"></span>
          <span class="text-gray" v-text="props.item.type"></span>
          <span class="text-gray min-105" v-text="props.item.time"></span>
        </div>
      </template>
    </vueInfiniteAutoScroll>
  </common-container>
</template>

<script>
  import vueInfiniteAutoScroll from 'vue-infinite-auto-scroll'
  import CommonContainer from "../components/commonContainer/Index";
  import moment from 'moment';
  import { getEventrecordListByCondition } from "@/api/wlw";
  export default{
    name:'RightTop',
    components: {CommonContainer,vueInfiniteAutoScroll},
    data() {
      return {
        lists:[],
        timer:null
      }
    },
    mounted () {
      this.getDatas()
      this.$once('hook:beforeDestroy',()=>{
        clearTimeout(this.timer)
      })
    },
    methods: {
      getDatas() {
        getEventrecordListByCondition({
          eventtype:"2",
          // starttime:"",
          // endtime:"",
          pageno:"1",
          pagesize:"30"
        }).then(res=>{
          const {returncode,item} = res
          if(returncode==="0"){
            this.lists = item.map(v=>({
              name:v.adress,
              type:`${v.eventname}${v.thirdeventname?'('+v.thirdeventname+')':''}`,
              time:moment(v.eventstarttime, 'YYYYMMDDHHmmss').format("YYYY.MM.DD HH:mm:ss")
            }))
          }
          clearTimeout(this.timer)
          this.timer = setTimeout(this.getDatas,1000*60*5)
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .right-top{
    width: 100%;
    height: 50%;
  }
  .scroller-wrap{
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .flex-box{
    display: flex;
    justify-content: space-between;
    line-height: 2em;
    font-size: 0.85em;
    color: #8bb9f9;
  }
  .text-gray{
    color: #566e90;
  }
  .name-class{
    width: 42%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
  }
  .min-105{
    min-width: 105px;
  }
</style>