<template>
  <div class="middle-top">
    <div id="map"></div>
  </div>
</template>

<script>
  import GJSON from "../map/liuyang";
  import { mapState } from 'vuex'
  import { getIotMapdata } from "@/api/wlw";
  export default{
    name:'MiddleTop',
    components: {
    },
    computed: {
      ...mapState({
        wlwDate: state => state.main.wlwDate,
        areacode: state => state.main.areacode,
        fontSize: state => state.main.fontSize,
      })
    },
    watch: {
      wlwDate(val) {
        this.getDatas()
      }
    },
    data() {
      return {
        myChart:null,
        MapData:null,
        timer:null
      }
    },
    mounted () {
      console.log(GJSON);
      this.getDatas()
      const resize = ()=>{
        setTimeout(()=>{
          if(this.myChart){this.myChart.resize()}
        })
      }
      window.addEventListener('resize',resize)
      this.$once('hook:beforeDestroy',()=>{
        clearTimeout(this.timer)
        window.removeEventListener('resize',resize)
      })
    },
    methods: {
      getDatas(){
        if(!this.wlwDate){return}
        getIotMapdata({
          areacode:this.areacode,
          starttime:this.wlwDate
        }).then(res=>{
          if(res.returncode==="0"){
            this.MapData = res.item
            this.initMap()
          }
          clearTimeout(this.timer)
          this.timer = setTimeout(this.getDatas,1000*60*5)
        })
      },
      initMap() {
        let mapData = GJSON
        if(!this.myChart){
          this.myChart = this.echarts.init(document.getElementById("map"));
        }
        this.echarts.registerMap('wuxi',mapData);
        let centerMap = {};
        let city = ""
        let zb = []
        const OBJ_KEY = {
          '报警次数': "alarmCount",
          '困人次数': "trapCount",
          '故障次数': "faultCount",
          '电瓶车事件次数': "batteryCarCount",
          '阻门事件次数': "chokeGateCount",
          '危险动作事件次数': "dangerousActionCount"
        }
        const COORDS_LINE = {
          '报警次数': [113.030098, 28.457888],
          '困人次数': [113.030098, 28.237888],
          '故障次数': [113.030098, 27.957888],
          '电瓶车事件次数': [114.330098, 28.487888],
          '阻门事件次数': [114.230098, 28.237888],
          '危险动作事件次数': [114.230098, 27.957888]
          // '报警次数': [119.157298, 31.885559],
          // '困人次数': [119.157298, 31.572559],
          // '故障次数': [119.157298, 31.286559],
          // '电瓶车事件次数': [120.857298, 31.885559],
          // '阻门事件次数': [120.857298, 31.556559],
          // '危险动作事件次数': [120.857298, 31.286559]
        }
        if (mapData && mapData.features && mapData.features.length > 0) {
            for(let i =0;i< mapData.features.length;i++){
                let feature = mapData.features[i]
                let properties = feature.properties || {};
                if (properties && properties.name) {
                  if(i===0){
                    city = properties.name
                    zb = properties.center
                  }
                  centerMap[properties.name] = properties.center || [];
                } 
            }
        }
        let option = {
          visualMap: {
            show: false,
            max: 100,
            seriesIndex: [3],
            inRange: {
              color: ['#A5DCF4', '#006edd'],
            },
          },
          geo: [{//如果在vue里effectScatter没生效，一定要看一下有没有引入geo插件
            map: 'wuxi',
            roam: false, // 是否允许缩放
            zoom: 1.1, // 默认显示级别
            color:"#fff",
            scaleLimit: {
              min: 0,
              max: 3,
            }, // 缩放级别
            itemStyle: {
              normal: {
                areaColor: '#013C62',
                shadowColor: '#013C62',
                shadowBlur: 20,
                shadowOffsetX: -5,
                shadowOffsetY: 15,
                borderColor: 'rgba(147, 235, 248, 1)',
                borderWidth: 1,
                areaColor: {
                  type: 'radial',
                  x: 0.5,
                  y: 0.5,
                  r: 0.8,
                  colorStops: [{
                      offset: 0,
                      color: 'rgba(147, 235, 248, 0)' // 0% 处的颜色
                  }, {
                      offset: 1,
                      color: 'rgba(147, 235, 248, .2)' // 100% 处的颜色
                  }],
                  globalCoord: false // 缺省为 false
                },
              },
            },
            tooltip: {
              show: false,
            },
          }],
          series: [
            {//起泡标记点
              type: 'effectScatter',
              coordinateSystem: 'geo',
              z: 5,
              data: [{
                city,
                value: zb,
              }],
              label:{
                show:true,
                position:"bottom",
                color:"#fff",
                formatter: (params)=>{
                  return (
                    `{name|${params.data.city}}\n{value|${this.MapData['liftCount']}台}`
                  );
                },
                rich: {
                  name: {
                    fontSize: this.fontSize*1.5,
                    padding: [5, 0]
                  },
                  value: {
                    fontSize: this.fontSize*1.5,
                  }
                }
              },
              symbolSize: 14,
              itemStyle: {
                color: '#feae21',
              }
            },
            // {
            //   type: 'map',
            //   zoom: '1.1',
            //   mapType: 'wuxi', // 自定义扩展图表类型
            //   legend: {
            //     show: true,
            //   },
            //   label: {
            //     show: true,
            //     emphasis:{//高亮时文字样式
            //       color:'#fff'
            //     }
            //   },
            //   itemStyle: {
            //     normal: {
            //       areaColor: 'white',
            //       color: 'rgba(241, 109, 115, .8)',
            //     },
            //     emphasis: {
            //       areaColor: 'rgb(0, 102, 255)',
            //     },
            //   },
            //   tooltip: {
            //     show: false,
            //   },
            // },
            {
              type: 'lines',
              z: 3,
              coordinateSystem: 'geo',
              symbol: 'circle',
              symbolSize: [6, 0],
              opacity: 1,
              effect: {
                show: true,
                period: 3, //箭头指向速度，值越小速度越快
                trailLength: 0, //特效尾迹长度[0,1]值越大，尾迹越长重
                color: '#fff', //箭头图标
                symbolSize: 8, //图标大小
              },
              label: {
                show: true,
                position: 'end',
                formatter: [
                  `{title|{b}}`,
                  `{value|{c}}`
                ].join('\n'),
                width: 100,
                rich: {
                  title: {
                    lineHeight: 17,
                    fontSize: this.fontSize,
                    color: '#fff',
                    width: 100,
                    height: 30,
                  },
                  value: {
                    height: 45,
                    width: 100,
                    fontSize:this.fontSize*2,
                    color: '#fff',
                  }
                }
              },
              lineStyle: {
                width:3,
                type: 'solid',
                opacity: 1,
                color: '#ccc',
              },
              data: Object.keys(COORDS_LINE).map((item) => {
                return Object.assign({
                  coords: [zb,COORDS_LINE[item]],
                }, {name:item,value:this.MapData[OBJ_KEY[item]]});
              })
            }
          ],
        };
        this.myChart.setOption(option)
        this.myChart.on('click',(event)=> {
          // if(event.componentType === 'series' && event.seriesIndex === 1){
          // if(event.componentType === 'geo'){
          //   var codeCity = event.name;
          //   var coord = centerMap[codeCity];
          //   option.series[0].data = [{
          //     city: codeCity,
          //     value: coord
          //   }];
          //   option.series[1].data = Object.keys(COORDS_LINE).map((item) => {
          //     return Object.assign({
          //       coords: [coord,COORDS_LINE[item]],
          //     }, {name:item,value:Math.floor(Math.random()*300)});
          //   });
          //   this.myChart.setOption(option, true);
          //   //由于绑定了mouseover事件，地图默认的高亮事件不触发了
          //   //所以手动高亮一下
          //   this.myChart.dispatchAction({
          //     type: 'highlight',
          //     seriesIndex: event.seriesIndex,
          //     dataIndex: event.dataIndex
          //   })
          // }
        }) 
      }
    },
  }
</script>

<style lang="scss" scoped>
  .middle-top{
    width: 100%;
    height: 100%;
  }
  #map{
    width: 100%;
    height: 90%;
  }
</style>