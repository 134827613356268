<template>
  <common-container title="温度-物联网故障" class="bottom-four">
    <LineCharts id="wlwgz" :lists="datas" :areaColor="areaColor" :colors="colors" />
  </common-container>
</template>

<script>
  import CommonContainer from "../components/commonContainer/Index";
  import LineCharts from "../components/lineCharts/Index";
  export default{
    name:'BottomFour',
    components: {CommonContainer,LineCharts},
    props: {
      lists: {
        type: Object,
        default: ()=>{}
      },
    },
    watch: {
      lists(val) {
        const {currentMonthTemp,lastMonthTemp} = val
        let todayDatas = Array(31).fill(0);
        let lastDatas = Array(31).fill(0);
        currentMonthTemp.reduce((bf,af)=>{
          const {date,num} = af
          bf[date.slice(-2)*1] = num
          return bf
        },todayDatas)
        lastMonthTemp.reduce((bf,af)=>{
          const {date,num} = af
          bf[date.slice(-2)*1] = num
          return bf
        },lastDatas)
         this.datas = {
          '本月':todayDatas,
          '上月':lastDatas
        }
      }
    },
    data() {
      return {
        colors:['#723FBE','#78365D'],
        areaColor:[],
        datas:{

        }
      }
    },
    methods: {
      initColor() {
        let echarts = this.echarts
        this.areaColor =  [
          new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
              offset: 1,
              color: '#5a57ef'
          }, {
              offset: 0.4,
              color: '#7f50e8'
          }, {
              offset: 0,
              color: '#a348e0'
          }]),
          new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
              offset: 0,
              color: '#ce236e'
          }, {
              offset: 0.7,
              color: '#d95463'
          }, {
              offset: 1,
              color: '#e38458'
          }])
        ]
      }
    },
    mounted () {
      this.initColor()
      // let todayDatas = [];
      // let lastDatas = [];
      // for(let i=0;i<30;i++){
      //   todayDatas.push(Math.floor(Math.random(0,1)*100))
      //   lastDatas.push(Math.floor(Math.random(0,1)*100))
      // }
      // this.datas = {
      //   '本月':todayDatas,
      //   '上月':lastDatas
      // }
    },
  }
</script>

<style lang="scss" scoped>
  .bottom-four{
    float: left;
    width: 16.66%!important;
    height: 100%;
  }
</style>