<template>
  <common-container title="电瓶车事件趋势" class="bottom-two">
    <LineCharts id="dpcsjqs" :lists="datas" :areaColor="areaColor" :colors="colors"  type="poly" />
  </common-container>
</template>

<script>
  import CommonContainer from "../components/commonContainer/Index";
  import LineCharts from "../components/lineCharts/Index";
  export default{
    name:'BottomTwo',
    components: {CommonContainer,LineCharts},
    data() {
      return {
        colors:['#19AFB3','#88762A'],
        areaColor:[],
        datas:{
        }
      }
    },
    props: {
      lists: {
        type: Object,
        default: ()=>{}
      },
    },
    watch: {
      lists(val) {
        const {currentMonthCar,lastMonthCar} = val
        let todayDatas = Array(31).fill(0);
        let lastDatas = Array(31).fill(0);
        currentMonthCar.reduce((bf,af)=>{
          const {date,num} = af
          bf[date.slice(-2)*1] = num
          return bf
        },todayDatas)
        lastMonthCar.reduce((bf,af)=>{
          const {date,num} = af
          bf[date.slice(-2)*1] = num
          return bf
        },lastDatas)
         this.datas = {
          '本月':todayDatas,
          '上月':lastDatas
        }
      }
    },
    methods: {
      initColor() {
        let echarts = this.echarts
        this.areaColor =  [
        new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
              offset: 1,
              color: '#29a657'
          }, {
              offset: 0.4,
              color: '#159f7f'
          }, {
              offset: 0,
              color: '#0098a6'
          }]),
          new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
              offset: 1,
              color: '#f3ff36'
          },{
              offset: 0.7,
              color: '#f3d31b'
          }, {
              offset: 0,
              color: '#f3a600'
          }])
        ]
      }
    },
    mounted () {
      this.initColor()
    },
  }
</script>

<style lang="scss" scoped>
  .bottom-two{
    float: left;
    width: 16.66%!important;
    height: 100%;
  }
</style>