<template>
  <div :id="id" style="height: 100%;width: 100%;"></div>
</template>

<script>
  import { mapState } from "vuex";
  export default{
    name:"pieCharts",
    props: {
      id: {
        type: String,
        default: ''
      },
      lists:{
        type:Array,
        default:()=>[]
      },
      colors:{
        type:Array,
        default:()=>[]
      }
    },
    computed: {
      ...mapState({
        fontSize: state => state.main.fontSize,
      })
    },
    data() {
      return {
        myChart: null
      }
    },
    mounted () {
      this.init()
      const resize = ()=>{
        setTimeout(()=>{
          if(this.myChart){this.myChart.resize()}
        })
      }
      window.addEventListener('resize',resize)
      this.$once('hook:beforeDestroy',()=>{
        window.removeEventListener('resize',resize)
      })
    },
    watch:{
      lists(val){
        this.init()
      }
    },
    methods: {
      init() {
        if(!this.lists||!this.lists.length){
          this.myChart&&this.myChart.clear()
          return
        }
        // 基于准备好的dom，初始化echarts实例
        if(!this.myChart){
          this.myChart = this.echarts.init(document.getElementById(this.id));
        }
        // 绘制图表
        // let legend = Object.keys(this.lists)
        // let maxLen = Object.values(this.lists).reduce((a,b)=>{
        //   a = Math.max(a,b.length)
        //   return a
        // },0)+1
        let option = {
          tooltip: {
            trigger: 'item',
            formatter: '{b} : {c} ({d}%)'
          },
          color:[
            new this.echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#4df798' },
              { offset: 1, color: '#32c2a5' },
            ]),
            new this.echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#C1358D' },
              { offset: 1, color: '#E58B55' },
            ]),
            new this.echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#9651E3' },
              { offset: 1, color: '#6256ED' },
            ]),
          ],
          series: [
            {
              type: 'pie',
              radius: this.fontSize===14?[30, 55]:[30, 85],
              center: ['50%', '50%'],
              // roseType: 'area',
              data: this.lists,
              label: {
                formatter: params => {
                  let dataIndex = params.dataIndex
                  const datas = {
                    0:'a',
                    1:'b',
                    2:'c',
                  }
                  return (
                    `{${datas[dataIndex]}| ${params.name} }`
                  );
                },
                rich: {
                  a: {
                    color:'#32c2a5',
                    fontSize: this.fontSize,
                    padding: [0, 10, 0, 4]
                  },b: {
                    color:'#ff6f64',
                    fontSize: this.fontSize,
                    padding: [0, 10, 0, 4]
                  },c: {
                    color:'#8a7aff',
                    fontSize: this.fontSize,
                    padding: [0, 10, 0, 4]
                  }
                }
              },
              animationType: 'scale',
              animationEasing: 'elasticOut',
              animationDelay: function (idx) {
                  return Math.random() * 200;
              }
            }
          ]
        };

        this.myChart.setOption(option);
      }
    },
  }
</script>