<template>
  <common-container title="阻门事件趋势" class="bottom-three">
    <LineCharts id="zmsjqs" :lists="datas" :areaColor="areaColor" :colors="colors" type="poly" />
  </common-container>
</template>

<script>
  import CommonContainer from "../components/commonContainer/Index";
  import LineCharts from "../components/lineCharts/Index";
  export default{
    name:'BottomThree',
    components: {CommonContainer,LineCharts},
    props: {
      lists: {
        type: Object,
        default: ()=>{}
      },
    },
    watch: {
      lists(val) {
        const {currentMonthGate,lastMonthGate} = val
        let todayDatas = Array(31).fill(0);
        let lastDatas = Array(31).fill(0);
        currentMonthGate.reduce((bf,af)=>{
          const {date,num} = af
          bf[date.slice(-2)*1] = num
          return bf
        },todayDatas)
        lastMonthGate.reduce((bf,af)=>{
          const {date,num} = af
          bf[date.slice(-2)*1] = num
          return bf
        },lastDatas)
         this.datas = {
          '本月':todayDatas,
          '上月':lastDatas
        }
      }
    },
    data() {
      return {
        colors:['#0DB3B0','#8E1383'],
        areaColor:[],
        datas:{

        }
      }
    },
    methods: {
      initColor() {
        let echarts = this.echarts
        this.areaColor =  [
          new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
              offset: 1,
              color: '#4cff8e'
          }, {
              offset: 0.4,
              color: '#26f5c7'
          }, {
              offset: 0,
              color: '#00eaff'
          }]),
          new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
              offset: 1,
              color: '#993d1b'
          }, {
              offset: 0.7,
              color: '#ab2451'
          }, {
              offset: 0,
              color: '#bd0b87'
          }])
        ]
      }
    },
    mounted () {
      this.initColor()
    },
  }
</script>

<style lang="scss" scoped>
  .bottom-three{
    float: left;
    width: 16.66%!important;
    height: 100%;
  }
</style>