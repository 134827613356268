<template>
  <a-tabs default-active-key="1" type="card" :animated="false" id="self-left-top">
    <a-tab-pane key="1" tab="事件统计">
      <div class="padding height-100">
        <table class="table-style">
          <tr>
            <td width="100px" class="sp-cl"></td>
            <td class="sp-cl">今日</td>
            <td class="sp-cl">本周</td>
            <td class="sp-cl">本月</td>
          </tr>
          <tr>
            <td class="sp-cl">报警</td>
            <td>{{allData.alarmCount[0]||0}}</td>
            <td>{{allData.alarmCount[1]||0}}</td>
            <td>{{allData.alarmCount[2]||0}}</td>
          </tr>
          <tr>
            <td class="sp-cl">困人</td>
            <td>{{allData.trapCount[0]||0}}</td>
            <td>{{allData.trapCount[1]||0}}</td>
            <td>{{allData.trapCount[2]||0}}</td>
          </tr>
          <tr>
            <td class="sp-cl">故障</td>
            <td>{{allData.faultCount[0]||0}}</td>
            <td>{{allData.faultCount[1]||0}}</td>
            <td>{{allData.faultCount[2]||0}}</td>
          </tr>
          <tr>
            <td class="sp-cl">电瓶车事件</td>
            <td>{{allData.batterCarCount[0]||0}}</td>
            <td>{{allData.batterCarCount[1]||0}}</td>
            <td>{{allData.batterCarCount[2]||0}}</td>
          </tr>
          <tr>
            <td class="sp-cl">阻门事件</td>
            <td>{{allData.chokeGateCount[0]||0}}</td>
            <td>{{allData.chokeGateCount[1]||0}}</td>
            <td>{{allData.chokeGateCount[2]||0}}</td>
          </tr>
        </table>
      </div>
    </a-tab-pane>
    <a-tab-pane key="2" tab="物联网统计数据">
      <div class="padding height-100">
        <a-row>
          <a-col :span="12">
            <statistic model="lot" title="累计运行时间" :value="datas.accumulative_run_time" unit="分"></statistic>
          </a-col>
          <a-col :span="12">
            <statistic model="lot" title="累计运行次数" :value="datas.accumulative_run_num" unit="次"></statistic>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <statistic model="lot" title="当日开关门次数" :value="datas.today_door_num" unit="次"></statistic>
          </a-col>
          <a-col :span="12">
            <statistic model="lot" title="累计开关门次数" :value="datas.accumulative_door_num" unit="次"></statistic>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <statistic model="lot" title="当日人流量" :value="datas.today_passenger_num" unit="人次"></statistic>
          </a-col>
          <a-col :span="12">
            <statistic model="lot" title="累计人流量" :value="datas.accumulative_passenger_num" unit="人次"></statistic>
          </a-col>
        </a-row>
      </div>
    </a-tab-pane>
  </a-tabs>
</template>

<script>
  import { mapState } from 'vuex'
  import { getIotDateevent } from "@/api/wlw";
  import Statistic from "@/views/superviseBigData/components/statistic/Index";
  export default{
    name:'LeftTop',
    components: {
      Statistic,
    },
    computed: {
      ...mapState({
        areacode: state => state.main.areacode,
      })
    },
    props: {
      datas: {
        type: Object,
        default: ()=>({})
      },
    },
    data() {
      return {
        allData: {
          alarmCount:[],//报警次数
          chokeGateCount:[],//阻门事件次数
          trapCount:[],//困人次数
          batterCarCount:[],//电瓶车事件次数
          faultCount:[]//故障次数
        },
        timer:null
      }
    },
    mounted () {
      this.getIotDate()
      this.$once('hook:beforeDestroy',()=>{
        clearTimeout(this.timer)
      })
    },
    methods: {
      getIotDate() {
        getIotDateevent({
          areacode:this.areacode
        }).then(res=>{
          if(res.returncode==="0"){
            this.allData = res.item
          }
          clearTimeout(this.timer)
          this.timer = setTimeout(this.getIotDate,1000*60*5)
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .left-top{
    width: 100%;
    height: 50%;
  }
  #sjtj{
    width: 100%;
    height: 100%;
  }
  .padding{
    padding: 8px;
    height: 100%;
  }
  .height-100{
    height: 100%;
  }
  .sp-cl{
    color: #2C9F85;
  }
  .table-style{
    width: 100%;
    height: 100%;
    color: #86B3DF;
    font-size: 16px;
    border-top:1px dashed #1F3049;
    border-left:1px dashed #1F3049;
  }
  tr{
    border-bottom:1px dashed #1F3049;
    height: 16.67%;
  }
  td{
    padding: 4px 0;
    text-align: center;
    border-right:1px dashed #1F3049;
  }
  #self-left-top{
    width:100%;
    height: 50%;
  }
</style>

<style lang="scss">
  #self-left-top .ant-tabs-card-bar{
    border: 0;
    margin-bottom: 5px;
  }
  #self-left-top .ant-tabs-tab{
    padding: 5px 10px!important;
    font-size: 16px;
    height: 26px;
    line-height: 16px;
    color: #7c7c7c;
    background: url('~@/assets/image/lot/title-duan-gray.png') no-repeat;
    background-size: 100% 100%;
    border: 0;
    margin-right: 10px;
  }
  #self-left-top .ant-tabs-tab-active{
    color: #fff;
    background: url('~@/assets/image/lot/title-duan.png') no-repeat;
    background-size: 100% 100%;
  }
</style>