<template>
  <div class="lot-big-data">
    <div class="header">
      <div class="logo">
        <img src="~@/assets/image/logo.png">
        <span>优湖电梯物联网大数据视图</span>
      </div>
      <div class="flex">
        <div class="ball one">
          <span>电梯总数</span>
          <span>{{mainDate.liftnum}}</span>
        </div>
        <div class="ball two">
          <span>传感器</span>
          <span>{{mainDate.sensornum}}</span>
        </div>
        <div class="ball three">
          <span>主控板</span>
          <span>{{mainDate.maincontrolnum}}</span>
        </div>
      </div>
      <div class="fix-right">
        <div class="fr" style="cursor: pointer;">
          <a-icon v-if="!fullScreen" type="fullscreen" @click="requestFullScreen('enter')" />
          <a-icon v-else type="fullscreen-exit" @click="requestFullScreen" />
        </div>
        <div class="fr">
          <div>{{currArr[0]}}</div>
          <div class="lg-size">{{currArr[1]}}</div>
        </div>
      </div>
    </div>
    <div class="middle">
      <div class="left">
        <LeftTop :datas="mainDate.statuscount" /><LeftMiddle />
      </div>
      <div class="center">
        <MiddleTop />
      </div>
      <div class="right">
        <RightTop />
        <RightMiddle />
      </div>
    </div>
    <div class="bottom">
      <BottomOne :lists="allMonth.alarm" /><BottomTwo :lists="allMonth.car" /><BottomThree :lists="allMonth.gate" />
      <BottomFour :lists="allMonth.temp" /><BottomFive :lists="allMonth.trap" /><BottomSix />
    </div>
    <div class="select-month">
      <div class="month-date"
        :class="{'select-date': idx===2}"
        :style="{marginTop:(idx<2?(2-idx):(idx-2))*2.5+'px'}"
        v-for="(item,idx) in dateArr" 
        :key="idx" 
        @click="initDateArr(item)">
        {{item}}
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  import LeftTop from "../LeftTop/Index";
  import LeftMiddle from "../LeftMiddle/Index";
  import MiddleTop from "../MiddleTop/Index";
  import RightTop from "../RightTop/Index";
  import RightMiddle from "../RightMiddle/Index";
  import BottomOne from "../BottomOne/Index";
  import BottomTwo from "../BottomTwo/Index";
  import BottomThree from "../BottomThree/Index";
  import BottomFour from "../BottomFour/Index";
  import BottomFive from "../BottomFive/Index";
  import BottomSix from "../BottomSix/Index";
  import store from '@/store'
  import { mapState } from 'vuex'
  import { getIotMaindata,getIotMonthevent } from "@/api/wlw";
  export default {
    name: 'lotBigData',
    components: {
      LeftTop,LeftMiddle,
      RightTop,RightMiddle,
      MiddleTop,
      BottomOne,BottomTwo,BottomThree,BottomFour,BottomFive,BottomSix
    },
    data() {
      return {
        currArr:[],
        dateArr:[],
        currDate:'',
        mainDate:{
          liftnum:0,
          maincontrolnum:0,
          sensornum:0,
          statuscount:{
            accumulative_run_time:0,
            accumulative_door_num:0,
            accumulative_run_num:0,
            accumulative_run_time:0,
            today_passenger_num:0,
            today_door_num:0,
          }
        },
        allMonth:{//底部各种趋势统计
          car:null,
          alarm:null,
          trap:null,
          gate:null
        },
        timer1:null,
        timer2:null,
      }
    },
    computed: {
      ...mapState({
        fullScreen: state => state.main.fullScreen,
        areacode: state => state.main.areacode,
        fontSize: state => state.main.fontSize,
      })
    },
    watch: {
      currDate(val) {
        if(val){
          this.getIotMonth()
        }
      }
    },
    created () {
      this.getIotMain();
    },
    mounted () {
      this.initDate()
      this.initDateArr()
      function screenChange(e){
        store.commit('main/setFullScreen', document.fullscreen)
      }
      if(document.body.clientWidth>1360){
        store.commit('main/setFontSize', 18)
      }
      const keyDown = (e)=>{
        const {keyCode} = e
        if(keyCode===37){//左
          this.initDateArr(this.dateArr[1])
        }else if(keyCode===39){//右
          if(this.dateArr[3]){
            this.initDateArr(this.dateArr[3])
          }
        }
      }
      window.addEventListener('fullscreenchange',screenChange)
      window.addEventListener('keydown',keyDown)
      this.$once('hook:beforeDestroy',()=>{
        clearTimeout(this.timer1)
        clearTimeout(this.timer2)
        window.removeEventListener('fullscreenchange',screenChange)
        window.removeEventListener('keydown',keyDown)
      })
    },
    methods: {
      getIotMain(){//物联网大数据主视图
        getIotMaindata({areacode:this.areacode}).then(res=>{
          if(res.returncode==="0"){
            this.mainDate = res.item
          }
          clearTimeout(this.timer1)
          this.timer1 = setTimeout(this.getIotMain,1000*60*5)
        })
      },
      getIotMonth(){
        getIotMonthevent({
          areacode:this.areacode,
          starttime:this.currDate
        }).then(res=>{
          const {errormsg,returncode,...allMonth} = res
          if(returncode==="0"){
            this.allMonth = allMonth
          }
          clearTimeout(this.timer2)
          this.timer2 = setTimeout(this.getIotMonth,1000*60*5)
        })
      },
      initDate(){
        this.currArr = this.showDT()
        let timer = setInterval(()=>{
          this.currArr = this.showDT()
        },1000);
        this.$once('hook:beforeDestroy',()=>{
          clearInterval(timer);
          timer = null;
        })
      },
      initDateArr(date){
        let arr = []
        arr.push(moment(date?new Date(date):new Date()).subtract(2,'months').format('YYYY.MM'))
        arr.push(moment(date?new Date(date):new Date()).subtract(1,'months').format('YYYY.MM'))
        arr.push(moment(date?new Date(date):new Date()).format('YYYY.MM'))
        let date1 = moment(date?new Date(date):new Date()).subtract(-1,'months')
        arr.push(date1<moment()?date1.format('YYYY.MM'):'')
        let date2 = moment(date?new Date(date):new Date()).subtract(-2,'months')
        arr.push(date2<moment()?date2.format('YYYY.MM'):'')
        let currDate = moment(date?new Date(date):new Date()).format('YYYYMM')
        this.currDate = currDate
        store.commit('main/setWlwBigdate', currDate)
        this.dateArr = arr
      },
      showDT() {
        return [moment().format("YYYY.MM.DD"),moment().format("HH:mm:ss")]
      },
      requestFullScreen(type) {
        if(type==='enter'){
          let element = document.body
          if (element.requestFullscreen) {
              element.requestFullscreen();
          } else if (element.msRequestFullscreen) {
              element.msRequestFullscreen();
          } else if (element.mozRequestFullScreen) {
              element.mozRequestFullScreen();
          } else if (element.webkitRequestFullScreen) {
              element.webkitRequestFullScreen();
          }
        }else{
          if (document.cancelFullScreen) {
            document.cancelFullScreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen()
          }
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .lot-big-data {
    position: relative;
    font-size: 16px;
    color: #fff;
    height: 100%;
    padding: 0 10px;
    background-color: #000;
    background-image: url('~@/assets/image/lot/BG.jpg');
    background-size: 100% 100%;
  }
  .select-month{
    position: absolute;
    top:66.8%;
    left:50%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: center;
    font-size: 10px;
  }
  .month-date{
    width: 100px;
    cursor: pointer;
    color: #2c3d56;
    text-align: center;
  }
  .select-date{
    color: #3e6eb1;
    background: url('~@/assets/image/lot/timeline_arrow.png') no-repeat center;
    background-size: 5% 100%;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5em;
    text-align:center;
  }
  .logo{
    width: 30%;
    height: 50%;
    color: #a9caf8;
  }
  .flex{
    font-family: ds-digitalnormal;
    width: 40%;
    display: flex;
    justify-content: space-around;
    height: 100%;
  }
  .fix-right{
    width: 30%;
    font-family: ds-digitalnormal;
    color: #a4bbd8;
    font-size: 28px;
    line-height: 1.2em;
  }
  .fr{
    float: right;
  }
  .ball{
    font-family: ds-digitalnormal;
    font-size: 33px;
    width: 118px;
    height: 118px;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    >span{
      &:first-child{
        font-size: 15px;
      }
    }
  }
  .one{
    color: #49e4f2;
    background-image: url('~@/assets/image/lot/ball-1.png');
  }
  .two{
    color: #ff7a78;
    background-image: url('~@/assets/image/lot/ball-2.png');
  }
  .three{
    color: #ffa85b;
    background-image: url('~@/assets/image/lot/ball-3.png');
  }
  .lg-size{
    font-size: 40px;
  }
  .middle{
    width: 100%;
    height: calc(66.7% - 66.7px);
  }
  .bottom{
    width: 100%;
    height: calc(33.3% - 33.3px);;
  }
  .left,.center,.right{
    float: left;
    height: 100%;
  }
  .left,.right{
    width: 25%;
  }
  .center{
    width: 50%;
  }
</style>