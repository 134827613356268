<template>
  <common-container title="物联网故障类型" class="left-top">
    <pieCharts id="lotgzlx" :lists="datas" />
  </common-container>
</template>

<script>
  import CommonContainer from "../components/commonContainer/Index";
  import pieCharts from "../components/pieCharts/Index";
  import { mapState } from 'vuex'
  import { getIotTypeevent } from "@/api/wlw";
  export default{
    name:'LeftMiddle',
    components: {CommonContainer,pieCharts},
    computed: {
      ...mapState({
        wlwDate: state => state.main.wlwDate,
        areacode: state => state.main.areacode,
      })
    },
    data() {
      return {
        datas:null,
        timer:null
        // datas: [
        //   {value: 22, name: '开门故障'},
        //   {value: 28, name: 'xx故障'},
        //   {value: 20, name: '其他'},
        // ]
      }
    },
    watch: {
      wlwDate(val) {
        this.getDatas();
      }
    },
    mounted () {
      this.getDatas();
      this.$once('hook:beforeDestroy',()=>{
        clearTimeout(this.timer)
      })
    },
    methods: {
      getDatas() {
        if(!this.wlwDate){return}
        getIotTypeevent({
          areacode:this.areacode,
          starttime:this.wlwDate
        }).then(res=>{
          if(res.returncode==="0"){
            this.datas = res.item.map(v=>({
              value:v.num,
              name:v.eventname
            }))
            console.log(this.datas)
          }
          clearTimeout(this.timer)
          this.timer = setTimeout(this.getDatas,1000*60*5)
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .left-top{
    width: 100%;
    height: 50%;
  }
</style>