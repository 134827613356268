<template>
  <common-container title="电梯品牌统计" class="right-middle">
    <barCharts id="dtpptj" :lists="datas" />
  </common-container>
</template>

<script>
  import CommonContainer from "../components/commonContainer/Index";
  import barCharts from "../components/barCharts/Index";
  import { mapState } from 'vuex'
  import { getIotBranddata } from "@/api/wlw";
  export default{
    name:'RightMiddle',
    components: {CommonContainer,barCharts},
    computed: {
      ...mapState({
        areacode: state => state.main.areacode,
      })
    },
    data() {
      return {
        datas:null,
        timer:null
        // datas:[
        //   {key:'OTIS',value:'1000'},
        //   {key:'通力',value:'1200'},
        //   {key:'康力',value:'800'},
        //   {key:'ThyssenKrupp',value:'600'},
        //   {key:'富士达',value:'400'},
        // ]
      }
    },
    mounted () {
      this.getDatas()
      this.$once('hook:beforeDestroy',()=>{
        clearTimeout(this.timer)
      })
    },
    methods: {
      getDatas() {
        getIotBranddata({
          areacode:this.areacode
        }).then(res=>{
          if(res.returncode==="0"){
            this.datas = res.item.map(v=>({
              key:v.value,
              value:v.num
            }))
          }
          clearTimeout(this.timer)
          this.timer = setTimeout(this.getDatas,1000*60*5)
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .right-middle{
    width: 100%;
    height: 50%;
  }
</style>