<template>
  <common-container title="困人趋势" class="bottom-five">
    <LineCharts id="xsbjqs" :lists="datas" :areaColor="areaColor" :colors="colors" />
  </common-container>
</template>

<script>
import CommonContainer from "../components/commonContainer/Index";
import LineCharts from "../components/lineCharts/Index";
export default{
  name:'BottomFive',
  components: {CommonContainer,LineCharts},
  props: {
    lists: {
      type: Object,
      default: ()=>{}
    },
  },
  watch: {
    lists(val) {
      const {currentMonthTrop,lastMonthTrop} = val
      let todayDatas = Array(31).fill(0);
      let lastDatas = Array(31).fill(0);
      currentMonthTrop.reduce((bf,af)=>{
        const {date,num} = af
        bf[date.slice(-2)*1] = num
        return bf
      },todayDatas)
      lastMonthTrop.reduce((bf,af)=>{
        const {date,num} = af
        bf[date.slice(-2)*1] = num
        return bf
      },lastDatas)
       this.datas = {
        '本月':todayDatas,
        '上月':lastDatas
      }
    }
  },
  data() {
    return {
      colors:['#4150D2','#993A1B'],
      areaColor:[],
      datas:{

      }
    }
  },
  methods: {
    initColor() {
      let echarts = this.echarts
      this.areaColor =  [
        new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
            offset: 1,
            color: '#8080ff'
        }, {
            offset: 0.4,
            color: '#6666ff'
        }, {
            offset: 0,
            color: '#4c4cff'
        }]),
        new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
            offset: 0,
            color: '#f04907'
        }, {
            offset: 0.7,
            color: '#f86c34'
        }, {
            offset: 1,
            color: '#ff8e61'
        }])
      ]
    }
  },
  mounted () {
    this.initColor()
  },
}
</script>

<style lang="scss" scoped>
  .bottom-five{
    float: left;
    width: 16.66%!important;
    height: 100%;
  }
</style>