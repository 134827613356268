<template>
  <common-container title="场所困人统计" class="bottom-six">
    <BarCharts id="cskrtj" :lists="datas" types="vertial" />
  </common-container>
</template>

<script>
  import CommonContainer from "../components/commonContainer/Index";
  import BarCharts from "../components/barCharts/Index";
  import { mapState } from 'vuex'
  import { getIotPlaceevent } from "@/api/wlw";
  export default{
    name:'BottomSix',
    components: {CommonContainer,BarCharts},
    computed: {
      ...mapState({
        wlwDate: state => state.main.wlwDate,
        areacode: state => state.main.areacode,
      })
    },
    watch: {
      wlwDate(val) {
        this.getDatas()
      }
    },
    data() {
      return {
        datas:null
        // datas:[
        //   {key:'医院',value:'300'},
        //   {key:'商场超市',value:'160'},
        //   {key:'文体娱乐场所',value:'110'},
        //   {key:'宾馆酒店',value:'260'},
        //   {key:'学校',value:'180'},
        //   {key:'交通场所',value:'220'},
        //   {key:'办公楼宇',value:'270'},
        //   {key:'住宅',value:'310'},
        //   {key:'其他',value:'120'},
        // ]
      }
    },
    mounted () {
      this.getDatas();
    },
    methods: {
      getDatas() {
        if(!this.wlwDate){return}
        getIotPlaceevent({
          areacode:this.areacode,
          starttime:this.wlwDate
        }).then(res=>{
          if(res.returncode==="0"){
            this.datas = res.item.map(v=>({
              key:v.value,
              value:v.num
            }))
          }
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .bottom-six{
    float: left;
    width: 16.66%!important;
    height: 100%;
  }
</style>